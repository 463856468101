@import '@nerdwallet/base-styles/styles.scss';
@import '../../../styles/front-page-header-variables.scss';

.card {
  @include display-flex;
  @include flex-direction-row;
  @include justify-content-space-between;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  @media (max-width: 1279px) {
    @include flex-direction-column;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.cardTextAndCTA {
  width: 50%;
  @include display-flex;
  @include flex-direction-column;
  @include justify-content-center;
  @include align-items-flex-start;
  gap: 32px;
  padding: 64px 48px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 24px 16px;
    gap: 24px;
    a {
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    width: 100%;
    padding: 32px 52px 24px 52px;
    gap: 24px;
    a {
      width: 50%;
    }
  }
}

.cardText {
  color: $green-darkest; // #00482f
  font-family: Gotham;
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.12px;

  @media (max-width: 1279px) {
    font-size: 18px;
    letter-spacing: 0.09px;
  }
}

.mobileImage {
  display: none;
  width: 100%;
  padding-bottom: 64px;
  img {
    object-fit: contain;
    max-width: 720px;
    max-height: 820px;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 767px) {
    display: flex;
  }
}

.tabletImage {
  display: none;
  width: 100%;
  padding-bottom: 64px;
  img {
    object-fit: contain;
    max-width: 1279px;
    max-height: 549px;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    display: flex;
  }
}

.desktopImage {
  display: none;
  width: 50%;
  img {
    object-fit: contain;
    max-width: 536px;
    max-height: 400px;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1280px) {
    display: flex;
  }
}
