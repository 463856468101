@import '../../../styles/util-styles/aligned';

.container {
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  @media (min-width: 376px) and (max-width: 768px) {
    padding-top: 48px;
  }
  @media (max-width: 375px) {
    padding-top: 32px;
  }
}

.title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.testimonialsCardContainer {
  display: flex;
  gap: 24px;
  margin-top: 68px;
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 32px;
  }
  @media (max-width: 767px) {
    margin-top: 32px;
    padding: 0 28px;
    gap: 14px;
    flex-direction: column;
    max-width: 500px;
  }
}

.testimonialsCard {
  position: relative;
  width: 210px;
  height: 130px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  @media #{$max-tablet} {
    &:nth-child(2) {
      align-self: flex-end;
    }
  }
}

.testimonialsCardTitle {
  position: relative;
  font-size: 54px;
  font-family: Chronicle;
  color: #00482f;
  line-height: 50px;
}

.testimonialsLeftContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;

  @media #{$max-tablet} {
    display: initial;
  }
}

.testimonialsCardSubtitle {
  position: relative;
  color: #00482f;
  font-size: 16px;
  font-family: Gotham;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.078px;
  font-weight: 500;
  svg {
    width: 11px;
    height: 11px;
  }
}

.QRWrapper {
  display: flex;
  @media #{$max-tablet} {
    display: none;
  }
}

.QRCodeContainer {
  background: linear-gradient(180deg, #40ad87 -9.52%, #008254 131.97%);
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.QRCodeText {
  font-size: 16px;
  color: #fff;
}

.QRCodeImage {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

.QRCodeFeaturedImage {
  height: 575px;
  width: 100%;
  object-fit: contain;
  object-position: bottom;

  @media #{$max-tablet} {
    height: 520px;
  }

  @media #{$mobile-only} {
    height: unset;
  }
}

.testimonialsContent {
  display: flex;

  @media #{$max-tablet} {
    flex-direction: column;
  }
}

.appStoreIconsContainer {
  display: none;
  @media (min-width: 768px) and (max-width: 1023px) {
    display: flex;
    gap: 12px;
    padding-top: 32px;
    padding-bottom: 32px;
    justify-content: flex-start;
  }
  @media (max-width: 767px) {
    display: flex;
    gap: 12px;
    padding-top: 32px;
    padding-bottom: 32px;
    justify-content: center;
  }
}

.appStoreIcons {
  width: 165px;
  justify-content: center;
  object-fit: contain;
}

.carouselContainer {
  background-color: #40ad87;
  padding-top: 48px;
  padding-bottom: 148px;
  @media (max-width: 768px) {
    padding-bottom: 64px;
  }
  position: relative;

  ul,
  html [type='ul'] .testimonial-carousel .slick-dots {
    display: none !important;
    @media #{$mobile-only} {
      display: flex !important;
    }
  }
}

.testimonialTile {
  cursor: pointer;
  padding: 32px;
  background-color: #e3faf2;
  border-radius: 8px;
  margin: 0 12px;
  width: 290px;
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    background-color: #b7ecda;
  }
}

.testimonialTileText {
  color: #006642;
  font-size: 20px;
  line-height: 130%;
  font-weight: bold;
}

.testimonialTileAuthor {
  color: #006642;
  font-size: 14px;
  font-weight: bold;
}

.carouselNav {
  display: none;

  @media #{$min-tablet} {
    display: flex;
    justify-content: flex-end;
    padding-top: 32px;
    gap: 12px;
  }
}

.carouselNavButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #008254;
  color: #fff;
  box-shadow: 0px 1px 2px 0px #00482f;
  border: none;
  border-radius: 50%;
  padding: 0;
  height: 30px;
  width: 30px;
  &:hover {
    background-color: #006642;
  }
}
