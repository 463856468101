@mixin roundedCornersMixin() {
  border-radius: 16px;
}

.roundedCorners {
  @include roundedCornersMixin;
}

.smallCorners {
  border-radius: 8px;
}

.backgroundPosition {
  position: absolute !important;
  top: -18px;
  width: 93% !important;
}

.folderBackground {
  background-image: url('https://www.nerdwallet.com/cdn-cgi/image/format=webp,quality=80/cdn/apps/prod/front-page/images/homepage/superbowl/ragged-texture-2.png');
  background-size: contain;
  overflow: hidden;
}

.hideOnMobile {
  @media (max-width: 768px) {
    display: none;
  }
}

.tabContentBorderBottom {
  box-shadow:
    0px 8px 0px 0px #008254,
    0px 16px 0px 0px #40ad87;
}

.container {
  width: 100%;
  position: relative;
}

.tab {
  height: 45px;
  margin-bottom: -15px;
  border-radius: 7px;
}

.tabSkew {
  height: 45px;
  border-radius: 7px;
  transform: skew(36deg);
  margin-left: 30px;
  position: absolute;
  left: 0;
  top: 0;
}

.tabContent {
  border-top-left-radius: 0;
  position: relative;
}
