@import '@nerdwallet/base-styles/styles.scss';
@import '../styles/front-page.scss';
@import '../styles/front-page-header-variables.scss';

$image-width: 112px;
$image-height: 66px;

.mainWrapper {
  background-color: $green-dark;
}

.nerdwalletPlusWrapper {
  padding-top: 64px;
}

.contentWrapper {
  @include padding-top-4;
  padding-bottom: 96px;

  @media #{$front-page-breakpoint-medium-desktop} {
    padding-left: var(--global-nav-gutter);
    padding-right: var(--global-nav-gutter);
  }

  @media #{$front-page-breakpoint-superwide-desktop} {
    @include max-width-container;
    @include margin-horizontal-auto;
  }
}

.titleSize {
  font-size: 38px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 769px) and (max-width: 1279px) {
    font-size: 20px;
  }
}

.heading {
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  color: #ffffff;
  @media (min-width: 769px) and (max-width: 1279px) {
    padding: 32px 52px 32px 52px;
  }
  @media (max-width: 768px) {
    padding: 32px 16px 32px 16px;
  }
}

.banner {
  display: flex;
  flex-direction: row;
  background: $green;
  color: $green-lighter-2;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 30px;

  @media (max-width: $front-page-small-breakpoint) {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    line-height: 18px;
  }
}

.imageWrapper {
  position: relative;
}

.image {
  position: absolute;
  bottom: -8px;

  background-size: $image-width $image-height;
  width: $image-width;
  height: $image-height;
  background-image: url('https://www.nerdwallet.com/cdn-cgi/image/format=webp,quality=80/cdn/front-page-next/beluga_final.png');
}

.textWrapper {
  margin-left: $image-width;

  /*@media (max-width: $front-page-small-breakpoint) {
    margin-left: $image-width-mobile;
  }*/

  span {
    font-size: 18px;
    letter-spacing: 0.09px;
    @media (max-width: 1279px) {
      font-size: 12px;
      letter-spacing: 0.06px;
    }
  }
}

.subHeading {
  @include padding-top-4;
  @media (max-width: 1279px) {
    display: none;
  }
}

.shoppingModuleWrapper {
  padding-top: 2rem;

  @media (max-width: 1279px) {
    padding: 1.5rem 0 0 0 !important;
  }
}

.shoppingModuleWithoutBanner {
  @media (max-width: 1279px) {
    padding: 0 !important;
  }
}
